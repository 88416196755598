<template>
  <div class="profil">
    <Header title="Profil" app />
    <Navbar />
    <div class="loader" v-if="!user">
      <Spinner />
    </div>
    <div class="profil__container" v-else>
      <div class="profil__box">
        <div class="profil__header">
          <div class="profil__header-img" @click="chooseHandler">
            <img :src="user.data.img" alt="#" v-if="user.data.img" />
            <img src="../assets/default.jpg" alt="#" v-else />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.041"
              height="12.258"
              viewBox="0 0 14.041 12.258"
            >
              <path
                id="Tracé_15"
                data-name="Tracé 15"
                d="M10.436,1.47h1.056a2.545,2.545,0,0,1,2.538,2.538V9.72a2.545,2.545,0,0,1-2.538,2.538H2.528A2.545,2.545,0,0,1-.01,9.72V4.008A2.545,2.545,0,0,1,2.528,1.47H3.584A1.988,1.988,0,0,1,4.119.632,2.2,2.2,0,0,1,5.52,0H8.5A2.2,2.2,0,0,1,9.9.632a1.99,1.99,0,0,1,.535.838ZM7.01,3.77a3.094,3.094,0,1,1-2.187.906A3.084,3.084,0,0,1,7.01,3.77ZM8.525,5.349a2.142,2.142,0,1,0,.627,1.515A2.135,2.135,0,0,0,8.525,5.349Zm2.967-2.928h-1.46a.476.476,0,0,1-.476-.476.9.9,0,0,0-.313-.63A1.209,1.209,0,0,0,8.5.952H5.52a1.209,1.209,0,0,0-.743.364.9.9,0,0,0-.313.63.476.476,0,0,1-.476.476H2.528A1.592,1.592,0,0,0,.942,4.008V9.72a1.592,1.592,0,0,0,1.586,1.586h8.964A1.592,1.592,0,0,0,13.079,9.72V4.008a1.592,1.592,0,0,0-1.586-1.586Z"
                transform="translate(0.01 0)"
                fill="#fff"
              />
            </svg>
            <input
              type="file"
              ref="imageInput"
              style="display: none"
              @change="previewImage"
              accept="image/*"
            />
          </div>
          <div class="profil__header-name">
            <p>{{ user.data.pseudo }}</p>
            <small>Partage: En construction</small>
          </div>
        </div>
        <div class="subnav">
          <a class="edit" @click="onEdit" v-if="!edit">Edit</a>
          <a class="save" @click="onSave" v-else>Save</a>
          <h3>Information</h3>
        </div>

        <div class="profil__edit" v-if="!edit">
          <p class="profil__label">
            Nom d'utilisateur
            <b class="profil__input">{{ user.data.pseudo }}</b>
          </p>

          <p class="profil__label">
            Adresse mail <b class="profil__input">{{ user.data.email }}</b>
          </p>
          <p class="profil__label">
            Numéro de téléphone
            <b class="profil__input">{{ user.data.tel }}</b>
          </p>
          <p class="profil__label">
            Type de cancer:
            <b class="profil__input">{{ user.data.typeCancer }}</b>
          </p>
        </div>
        <div class="profil__edit" v-else>
          <p class="profil__label">
            Nom d'utilisateur
            <input
              class="profil__input"
              type="text"
              name="name"
              id="name"
              v-model="user.data.pseudo"
            />
          </p>
          <p class="profil__label">
            Adresse mail
            <input
              class="profil__input"
              type="email"
              name="email"
              id="email"
              v-model="user.data.email"
            />
          </p>
          <p class="profil__label">
            Numéro de téléphone
            <input
              class="profil__input"
              type="text"
              name="age"
              id="age"
              v-model="user.data.tel"
            />
          </p>
          <p class="profil__label">
            Type de cancer:
            <input
              class="profil__input"
              type="text"
              name="name"
              id="name"
              v-model="user.data.typeCancer"
            />
          </p>
        </div>
      </div>
      <div class="profil__partage">
        <div class="profil__title">
          <h3>Cercle de partage</h3>
        </div>

        <div class="profil__partage--user">
          <p>En construction</p>
        </div>
      </div>
      <div>
        <button @click="logoutHandler">Se déconnecter</button>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Spinner from "../components/Spinner.vue";
import Header from "../components/Header.vue";
import firebase from "firebase";
// @ is an alias to /src

export default {
  components: { Spinner, Navbar, Header },
  name: "Profil",
  data() {
    return {
      edit: false,
      imageUpload: null
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    onEdit() {
      this.edit = !this.edit;
    },
    onSave() {
      this.$store.dispatch("updateUserData", this.user.data);
      this.onEdit();
    },
    logoutHandler() {
      this.$store.dispatch("logout");
    },
    chooseHandler() {
      this.$refs.imageInput.click();
    },
    previewImage(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("bad files");
      }
      this.imageUpload = files[0];
      console.log(this.user.data);
      this.onUpload();
    },
    onUpload() {
      const metadata = {
        contentType: `${this.imageUpload.type}`
      };

      const filename = this.imageUpload.name;

      const storageRef = firebase
        .storage()
        .ref(`${this.$store.state.userId}/profil/${filename}`)
        .put(this.imageUpload, metadata);

      storageRef.on(
        `state_changed`,
        snapshot => {
          console.log(
            "Upload ",
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        error => {
          console.log(error.message);
        },
        () => {
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            console.log(url);

            const data = {
              ...this.user.data,
              img: url
            };

            this.$store.dispatch("updateUserData", data);
          });
        }
      );
    }
  }
};
</script>

<style lang="scss">
.profil {
  display: flex;
  flex-direction: column;
  h3 {
    text-transform: uppercase;
    color: #3b5150;
    text-align: left;
    margin-bottom: 0px;
  }

  &__partage {
    h3 {
      margin-bottom: 20px;
    }
    &--user {
      height: 70%;
      background-color: #ececec;
      padding: 30px;
      p {
        font-family: "Lora", serif;
        color: #23233c;
        font-size: 20px;
      }
    }
  }
  &__label {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    font-size: 16px;
    color: #3b5150;
    text-align: left;
  }
  &__input {
    padding: 20px;
    border: none;
    background-color: #fff;
    border-radius: 5px;
    margin: 10px 0px;
    column-gap: 20px;
    box-shadow: 0px 10px 10px rgba(13, 78, 129, 0.051);
    font-size: 14px;
    font-weight: 400;
  }

  &__container {
    margin: 0px auto 90px;
    width: 80%;

    button {
      color: #fff;
      padding: 15px 30px;
      background-color: #95cdcb;
      border: none;
      font-weight: 600;
      font-size: 15px;
      display: block;
      margin: 20px auto;
      border-radius: 18px;
      text-transform: uppercase;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    text-align: left;
    p {
      font-family: "Lora", serif;
      color: #23233c;
      font-weight: 600;
    }
    small {
      text-transform: uppercase;
    }
    &-img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 25px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }

      img {
        width: 100%;
        height: auto;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      svg {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: 15px;
        z-index: 2;
      }
    }
  }
}
.save {
  padding: 5px 20px;
  border: 1px solid green;
  border-radius: 10px;
  display: inline;
  cursor: pointer;
  color: green;
  transition: 0.3s all ease-in;

  &:hover {
    color: #fff;
    background-color: green;
    transition: 0.3s all ease-in;
  }
}

.subnav {
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  .edit {
    background-image: url(../assets/icon/edit.svg);
    background-repeat: no-repeat;
    overflow: hidden;
    display: block;
    text-indent: 300%;
    border: none;
    background-color: inherit;
    width: 30px;
    height: 30px;
    background-size: contain;
    transition: 0.3s all ease-in;
    cursor: pointer;
  }
  .save {
    background-image: url(../assets/icon/save.svg);
    background-repeat: no-repeat;
    overflow: hidden;
    display: block;
    text-indent: 300%;
    border: none;
    background-color: inherit;
    width: 30px;
    height: 30px;
    background-size: contain;
    transition: 0.3s all ease-in;
    padding: 0;
    cursor: pointer;
  }

  a {
    font-weight: bold;
    margin-right: 5px;
    &.active {
      color: #95cdcb;
    }
  }
}

@media (min-width: 850px) {
  .profil {
    &__container {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin: auto;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }
    &__box {
      min-width: 45%;
    }

    &__partage {
      min-width: 40%;
      padding: 50px;
      background-color: #fff;
    }
  }
}
</style>
